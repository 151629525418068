<!-- This modal is imported into feature-view.vue as a tab. This tab shows all the linked non-promotional items and allows the user to modify the net unit cost, gross profit, count, etc. as well as
delete the non promo item from the feature -->
<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize">
    <v-data-table
      :headers="headers"
      :height="tableHeight"
      :items.sync="items"
      loading-text="Retrieving Existing Data...Please Wait"
      :search="search"
      :loading="loading"
      :class="table"
      class="rounded-0"
      dense
      fixed-header
      hide-default-footer
      item-key="id"
      loader-height="5"
      :item-class="itemRowBackground"
      no-data-text="No Data to Display"
      :items-per-page="pageSize">

      <!-- <template #top>
        <v-divider/>
        <v-toolbar class="fill" flat>
          <v-row align="center">
          <v-col md="4" lg="3">
            <v-text-field :class="body" class="mt-7" label="Filter Items" outlined dense background-color="input"   prepend-inner-icon="mdi-filter-outline" v-model="search">
            </v-text-field>
          </v-col>
          </v-row>
        </v-toolbar>
      </template> -->

      <template #[`item.count_price`]="{ item }">
        <v-row dense style="height:60px;">
          <v-spacer/>
          <v-col cols="2">
            <v-menu
              v-model="editingQuantity"
              :close-on-content-click="false"
              :close-on-click="false"
              bottom
              right
              absolute
              nudge-top="-12px"
              nudge-left="16px"
              offset-y
              max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div class="edit-menu-container"
                  :style="!readonly ? 'cursor: pointer' : 'cursor: default'"
                  v-on="!readonly ? on : null"
                  v-bind="attrs"
                  @click="!readonly ? open(item) : null">
                  <span>
                    <div class="text-caption ma-0 pa-0">Qty</div>
                    <v-btn v-if="!readonly" class="mx-0 pa-0" depressed small>
                      {{ item.multiplier }}
                    </v-btn>
                    <div v-else class="ml-0 pa-0">
                      {{ item.multiplier }}
                    </div>
                  </span>
                </div>
              </template>
              <v-card class="pt-2 px-4" style="z-index: 97" tile flat v-if="editItem && editItem.id === item.id">
                <div style="margin-top: 8px">
                  <span class="subtitle-2">Edit Quantity</span>
                </div>
                  <div class="qty-controls ml-13">
                    <v-btn 
                      icon 
                      x-small
                      class="my-auto py-0 my-0"
                      fab
                      dense
                      color="primary"
                      :disabled="editItem.multiplier === 1"
                      @click="decreaseQty">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-text-field
                      ref="multiplier"
                      v-model="editItem.multiplier"
                      class="pt-2 mt-0"
                      :rules="[
                        v => !!v || 'Count Required',
                        v => !(v < 0) || 'Cannot have negative quantity'
                      ]"
                      placeholder="Qty"
                      min="1"
                      single-line
                      autofocus
                      hide-spin-buttons
                      type="number">
                    </v-text-field>
                    <v-btn
                      icon 
                      x-small
                      class="my-auto py-0 my-0"
                      fab
                      dense
                      color="primary"
                      @click="increaseQty">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                <v-card-actions class="pt-0 px-0">
                  <v-spacer/>
                  <v-btn
                    text
                    small
                    @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="!editItem.multiplier || editItem.multiplier < 0"
                    @click="save('multiplier')">
                    Save
                  </v-btn>
                  <v-btn
                    small
                    color="accent"
                    :loading="applyingAll"
                    :disabled="applyingAll || !editItem.multiplier || editItem.multiplier < 0"
                    @click="applyAll('multiplier')">
                    Apply All
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-icon class="mx-1 mt-2" small>mdi-at</v-icon>
          <v-col cols="4">
            <v-menu
              v-model="editingSalePrice"
              :close-on-content-click="false"
              :close-on-click="false"
              bottom
              right
              absolute
              nudge-top="-12px"
              nudge-left="16px"
              offset-y
              max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div class="edit-menu-container"
                  :style="!readonly ? 'cursor: pointer' : 'cursor: default'"
                  v-on="!readonly ? on : null"
                  v-bind="attrs"
                  @click="!readonly ? open(item) : null">
                  <span class="mx-0 pa-0">
                    <div class="text-caption ma-0 pa-0">Sale Price</div>
                    ${{ formatCurrencyDisplay(item.sale_price) }}
                  </span>
                  <v-icon class="ml-0 mt-4 pa-0" small v-if="!readonly">mdi-pencil</v-icon>
                </div>
              </template>
              <v-card class="pt-2 px-4" style="z-index: 98" tile flat v-if="editItem && editItem.id === item.id">
                <div style="margin-top: 8px">
                  <span class="subtitle-2">Edit Sale Price</span>
                </div>
                <v-text-field
                  ref="sale_price"
                  class="pt-0 mt-0"
                  v-model="editItem.sale_price"
                  :rules="[isValidPrice, v => !(v < 0) || 'Cannot have negative value.']"
                  autofocus
                  type="number"
                  hide-spin-buttons
                  prefix="$">
                </v-text-field>
                <v-card-actions class="pt-0 px-0">
                  <v-btn
                    text
                    small
                    @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="!editItem.sale_price || editItem.sale_price < 0"
                    @click="save('sale_price')">
                    Save
                  </v-btn>
                  <v-btn
                    small
                    color="accent"
                    :loading="applyingAll"
                    :disabled="applyingAll || !editItem.sale_price || editItem.sale_price < 0"
                    @click="applyAll('sale_price')">
                    Apply All
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-spacer/>
        </v-row>
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        <v-tooltip top v-if="(item.future_cost == item.cost) && (item.future_cost_date <= selectedAd.ad_end_date)" >
          <template v-slot:activator="{ on }">
            <span v-on="on" class="primary--text font-weight-bold">
              ${{formatCurrencyDisplay(item.cost)}}
            </span>
          </template>
          This item has a cost change on {{$dateConfig.formatDate(item.future_cost_date)}} <br>
          The new cost of ${{formatCurrencyDisplay(item.cost)}} will be used on this ad
        </v-tooltip>
        <span v-else> ${{formatCurrencyDisplay(item.cost)}} </span>
      </template>

      <template #[`item.net_unit_cost`]="{item}">
        <v-row dense class="ml-0 pa-0" style="height:60px;">
        <v-col cols="4">
            <v-menu
              v-model="editingNetUnitCost"
              :close-on-content-click="false"
              :close-on-click="false"
              bottom
              absolute
              nudge-top="-12px"
              nudge-left="16px"
              offset-y
              max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div class="edit-menu-container"
                  :style="!readonly ? 'cursor: pointer' : 'cursor: default'"
                  v-on="!readonly ? on : null"
                  v-bind="attrs"
                  @click="!readonly ? open(item) : null">
                  <span class="ml-0 mt-4 pa-0">
                    ${{ formatCurrencyDisplay(item.net_unit_cost) }}
                  </span>
                  <v-icon class="ml-2 mt-4 mb-1 pa-0" small v-if="!readonly">mdi-pencil</v-icon>
                </div>
              </template>
              <v-card class="pt-2 px-4" style="z-index: 99" tile flat v-if="editItem && editItem.id === item.id">
                <div style="margin-top: 8px">
                  <span class="subtitle-2">Edit Net Unit Cost</span>
                </div>
                <v-text-field
                  ref="net_unit_cost"
                  class="pt-0 mt-0"
                  v-model="editItem.net_unit_cost"
                  :rules="[isValidPrice, v => !(v < 0) || 'Cannot have negative value.']"
                  autofocus
                  type="number"
                  hide-spin-buttons
                  prefix="$">
                </v-text-field>
                <v-card-actions class="pt-0 px-0">
                  <v-spacer/>
                  <v-btn
                    text
                    small
                    @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="!editItem.net_unit_cost || editItem.net_unit_cost < 0"
                    @click="save('net_unit_cost')">
                    Save
                  </v-btn>
                  <v-btn
                    small
                    color="accent"
                    :loading="applyingAll"
                    :disabled="applyingAll || !editItem.net_unit_cost || editItem.net_unit_cost < 0"
                    @click="applyAll('net_unit_cost')">
                    Apply All
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>

      <template #[`item.pack_size`]="{ item }">
        <span v-if="item.pack_size">{{item.pack_size}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.gross_profit`]="{ item }">
       <span v-if="item.net_unit_cost&&item.sale_price&&item.multiplier">{{formatCurrencyDisplay(calcNonPromoGP(item))}}%</span>
       <span v-else>-</span>
      </template>

      <template v-slot:[`item.related`]="{ item }">
        <div v-if="item[tenantLinkedProperty]">
          <v-tooltip class="mx-auto" top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                color="primary"
                @click="viewRelatedItems(item)">
                <v-icon class="mx-auto">mdi-view-list</v-icon>
              </v-btn>
            </template>
            <span>Related Items</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn :disabled="readonly" icon small color="red" @click="removeItem(item)" v-on="on">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <RelatedItemsModal
      v-if="showRelatedItemsModal"
      v-model="showRelatedItemsModal"
      :item="currentItem"
      :currentItems="items"
      :readOnly="true"
      @close="closeRelatedItemsModal"
      @cancel="showRelatedItemsModal = false">
    </RelatedItemsModal>

  </v-container>
</template>
<script>
const RelatedItemsModal = () => import('@/components/features/RelatedItemsModal.vue')
import { displayHelpers } from '@/mixins/display-helpers'
import { format } from '@/mixins/format'
import { cloneDeep } from 'lodash'
export default {
  name: 'FeatureNonPromoItems',
  components: { RelatedItemsModal },
  mixins: [displayHelpers, format],
  props: [
    'selectedAd',
    'feature',
    'showInfo',
    'readonly'
  ],
  data: () => ({
    loading: false,
    applyingAll: false,
    items: [],
    editItem: null,
    search: '',
    tableHeight: '',
    offset: true,
    page: 1,
    pageSize: 50,
    pageSizes: [50, 100],
    disableNext: false,
    disablePrevious: true,
    showRelatedItemsModal: false,
    currentItem: null,
    editingQuantity: false,
    editingNetUnitCost: false,
    editingSalePrice: false
  }),
  computed: {
    tenantLinkedProperty() {
      return (this.$auth.tenant === 'pricechopper')
        ? 'pce_link_code'
        : 'linked_group'
    },
    headers () {
      let headers = [
        { sortable: false, class: 'accent white--text', show: true },
        { text: 'Item', groupable: false, align: 'start', sortable: true, class: 'accent white--text', value: 'item_id', show: true },
        { text: 'GTIN', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'gtin', show: this.$auth.tenant === 'pricechopper' },
        { text: 'Source', groupable: false, align: 'start', sortable: true, class: 'accent white--text', value: 'source', show: true },
        { text: 'Name', groupable: false, align: 'start', sortable: true, class: 'accent white--text', value: 'description', show: true },
        // { text: 'Retail Price', width: '20%', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'count_price', show: true },
        { text: 'Retail Price', width: '400px', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'count_price', show: true },
        { text: 'Unit Cost', groupable: false, align: 'start', sortable: true, class: 'accent white--text', value: 'net_unit_cost', show: true },
        { text: 'Case Cost', groupable: false, align: 'start', sortable: true, class: 'accent white--text', value: 'cost', show: true },
        { text: 'Pack Size', groupable: false, align: 'start', sortable: true, class: 'accent white--text', value: 'pack_size', show: true },
        { text: 'Gross Profit', groupable: false, align: 'start', sortable: false, class : 'accent white--text', value:'gross_profit', show: true },
        { text: 'Related Items', value: 'related', align: 'center',sortable: false, class: 'accent white--text', show: this.$auth.tenant === 'pricechopper' },
        { text: '', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'actions', show: true }
      ]
      return headers.filter(item => item.show)
    }
  },
  watch: {
    feature: {
      deep: true,
      immediate: true,
      handler () {
        if (this.feature.non_promo_items.length) {
          this.getNonPromoItems()
        }
      }
    },
    showInfo: {
      immediate: true,
      handler () {
        if (!this.showInfo) {
          this.tableHeight = window.innerHeight - 265
        } else {
          this.tableHeight = window.innerHeight - 340
        }
      }
    },
  },
  created () {
    this.tableHeight = window.innerHeight - 340
    this.$root.$on('get-items', () => { this.getNonPromoItems() })
  },
  methods: {
    open (item) {
      this.editItem = cloneDeep(item)
    },
    save (key) {
      this.closeEditDialogs()
      const valid = this.$refs[key].validate()
      if (valid) {
        const itemsClone = cloneDeep(this.items)
        itemsClone.find(item => this.editItem.id === item.id)[key] = this.editItem[key]
        this.feature.non_promo_items = itemsClone
        this.update()
      }
    },
    cancel () {
      this.editItem = null
      this.closeEditDialogs()
    },
    closeEditDialogs () {
      this.editingQuantity = false
      this.editingNetUnitCost = false
      this.editingSalePrice = false
    },
    applyAll (key) {
      const valid = this.$refs[key].validate()
      if (valid) {
        this.applyingAll = true
        try {
          const newValue = this.editItem[key]
          const itemsClone = cloneDeep(this.items)
          itemsClone.forEach(item => {
            item[key] = newValue
          })
          this.feature.non_promo_items = itemsClone
        } catch (err) {
          console.error(err)
        } finally {
          this.applyingAll = false
          this.closeEditDialogs()
          this.update()
        }
      }
    },
    increaseQty() {
      this.editItem.multiplier = parseInt(this.editItem.multiplier) + 1
    },
    decreaseQty() {
      this.editItem.multiplier = parseInt(this.editItem.multiplier) - 1
    },
    viewRelatedItems (item) {
      this.currentItem = item
      this.showRelatedItemsModal = true
    },
    closeRelatedItemsModal () {
      this.showRelatedItemsModal = false
    },
    calcNonPromoGP(item){
      const ad_retail = parseFloat(item.sale_price)
      const ad_count = parseFloat(item.multiplier)
      const net_unit_cost = parseFloat(item.net_unit_cost)
      return ((((ad_retail / ad_count)) 
      - net_unit_cost) 
      / ((ad_retail / ad_count)) * 100).toFixed(2)
    },
    onResize () {
      this.tableHeight = window.innerHeight - 340
    },
    itemRowBackground (item) {
      return this.items.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
    },
    async getNonPromoItems () {
      this.loading = true
      let items = []
      try {
        const res = await this.$Features.getAdFeatureById(this.feature.id)
        if (res?.data?.non_promo_items.length > 0) {
          items = res.data.non_promo_items.map(item => this.formatItemPrices(item))
        }
        this.items = items
      } catch (err) {
        console.error(err)
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Feature due to ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loading = false
      }
    },
    removeItem (item) {
      this.items = this._.reject(this.items, { 'item_id': item.item_id })
      this.feature.non_promo_items = this._.reject(this.feature.non_promo_items, { 'item_id': item.item_id })
      this.update()
    },
    async update () {
      try {
        await this.$Features.updateAdFeature(this.feature.id, this.feature)
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Feature Updated` })
        await this.getNonPromoItems()
      } catch (err) {
        console.error(err)
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update due to ${err?.response?.data?.message || err.message}` })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .active {
    box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
    background: #385F73;
    color: white !important;
  }
  .edit-menu-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    grid-column-gap: 0px;
    position: relative;
  }
  .qty-controls {
    display: grid; 
    width: 100%; 
    max-width: 100px; 
    grid-template-columns: auto 50px auto; 
    justify-content: flex-center;
  }
</style>
